import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TablePagination from './TablePagination';

interface Props {
  data: any;
  columns: any;
  onClick?: (value: any, event: any) => void;
  searchValue?: string;
  type?: string;
  removeItem?: (id: string) => void;
  setColumnVisibility?: any;
}

function Table(props: Props) {
  const [data, setData] = useState(() => [...props.data]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const columns = props.columns;
  const [loadingCell, setLoadingCell] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({ ...props.setColumnVisibility });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });

  useEffect(() => {
    setColumnVisibility({ ...props.setColumnVisibility });
  }, [props.setColumnVisibility]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.searchValue?.length && props.searchValue?.length > 0) table.setPageIndex(0);
    setGlobalFilter(props.searchValue || '');
  }, [props.searchValue]);

  const fuzzyFilter = (row: any, columnId: any, value: any, addMeta: any) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta(itemRank);
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    state: {
      sorting,
      globalFilter,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getPaginationRowModel: getPaginationRowModel(),
    enableColumnResizing: false,
  });

  return (
    <div className='max-w-full'>
      {table.getRowModel().rows.length === 0 ? (
        <div className='flex justify-center items-center p-4 bg-gray-3 border-x border-b border-gray-10'>
          <span className='text-gray-100 prose-paragraphBase italic'>{`No ${props.type} found.`}</span>
        </div>
      ) : (
        <table className='w-full'>
          <thead className='border border-t-0 border-gray-10 w-full'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className='w-full' key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <td
                    {...{
                      key: header.id,
                      style: { width: header.column.getSize() },
                    }}
                    className='prose-labelTableColumn max-w-xs text-gray-60 cursor-pointer py-5 overflow-hidden pl-6'
                    key={header.id}
                  >
                    <div
                      className={`${sorting[0]?.id === header.id ? 'text-gray-100' : ''} uppercase gap-1 flex`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon icon={faChevronUp} fontSize={12} />,
                        desc: <FontAwesomeIcon icon={faChevronDown} fontSize={12} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                      ></div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='border border-gray-10 max-w-full'>
            {table
              .getRowModel()
              .rows.slice(0, table.getState().pagination.pageSize)
              .map((row, index) => {
                return (
                  <tr
                    className='odd:bg-gray-3 hover:bg-gray-5 cursor-pointer'
                    key={row.id}
                    onClick={(event) => props.onClick && props.onClick(row.original, event)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              maxWidth: cell.column.getSize(),
                            },
                          }}
                          onClick={() => {
                            if (cell.column.id === 'id' && props.removeItem) {
                              setLoadingCell(cell.id);
                              props.removeItem(cell.row.original.id);
                            }
                          }}
                          className='overflow-ellipsis overflow-hidden pl-6 h-[60px] 2xl:prose-paragraphBase prose-paragraphSmaller text-gray-80'
                          key={cell.id}
                        >
                          <div className='overflow-ellipsis overflow-hidden'>
                            {loadingCell !== cell.id ? (
                              <div className='flex gap-1 overflow-ellipsis overflow-hidden'>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </div>
                            ) : (
                              <div className='w-4 h-4 border-l-2 border-primary-400 rounded-full animate-spin'></div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {data.length !== 0 && table.getRowModel().rows.length > 0 && <TablePagination table={table} />}
    </div>
  );
}

export default Table;
