import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import { SoftwareUpdate } from '../../models/ApiModels';

type SoftwareUpdateLíst = {
  id: string;
  systemType: string;
  unit: string;
  version: string;
  originalFileName?: string;
  officialRelease: boolean;
  isActive: boolean;
  description: string;
  fileSize?: number;
  softwareUpdate: SoftwareUpdate;
};
export function GetSoftwareUpdateListData(softwareUpdate: SoftwareUpdate[]) {
  const softwareUpdateList: SoftwareUpdateLíst[] = softwareUpdate.map((softwareUpdate: SoftwareUpdate) => {
    return {
      id: softwareUpdate.id,
      systemType: softwareUpdate.systemType,
      unit: softwareUpdate.unit,
      version: softwareUpdate.version,
      originalFileName: softwareUpdate.originalFileName,
      officialRelease: softwareUpdate.officialRelease,
      isActive: softwareUpdate.isActive,
      description: softwareUpdate.description,
      fileSize: softwareUpdate.fileSize,
      softwareUpdate: softwareUpdate,
    };
  });

  return softwareUpdateList;
}

const columnHelper = createColumnHelper<SoftwareUpdateLíst>();

export const softwareUpdateListColumns = [
  columnHelper.accessor('systemType', {
    header: 'System type',
    cell: (info) => info.getValue(),
    size: 50,
  }),
  columnHelper.accessor('unit', {
    header: 'Unit',
    cell: (info) => info.getValue(),
    size: 50,
  }),
  columnHelper.accessor('version', {
    header: 'Version',
    cell: (info) => info.getValue(),
    size: 50,
  }),
  columnHelper.accessor('originalFileName', {
    header: 'File name',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),

  columnHelper.accessor('officialRelease', {
    header: 'Version status',
    cell: (info) =>
      info.getValue() === true ? (
        <StatusPill color='Green' text='Official Release' />
      ) : (
        <StatusPill color='Dark Gray' text='Development' />
      ),
    size: 150,
  }),
  columnHelper.accessor('isActive', {
    header: 'Download status',
    cell: (info) =>
      info.getValue() === true ? (
        <StatusPill color='Green' text='Active' />
      ) : (
        <StatusPill color='Dark Gray' text='Inactive' />
      ),
    size: 150,
  }),
];
