import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { GetSystemListData, systemListColumns } from '../../../helpers/SystemListSetup';
import { BoatModel, System } from '../../../models/ApiModels';
import { GetSystemsAsync } from '../../../services/SystemService';
import { RootState } from '../../../store/reducers/combine';
import useRequest from '../../../utils/net/useRequest';
import useWindowSize from '../../../utils/UseWindowSize';
import Table from '../../Table/Table';
interface Props {
  boatModel: BoatModel;
}

function BoatDetailsSystems(props: Props) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const { isHumphreeUser } = useContext(AppContext);
  const [systems = [], isSystemIsLoading, systemsGetError] = useRequest(() => GetSystemsAsync(), []);
  const oems = useSelector((state: RootState) => state.oems.data);
  const features = useSelector((state: RootState) => state.configurations.features);
  const packages = useSelector((state: RootState) => state.configurations.packages);
  const boatModels = useSelector((state: RootState) => state.boatModels.boatModels);
  const size = useWindowSize();

  const connectedSystems = systems.filter((system: System) => system.boatModelId === props.boatModel.id);

  function goToConnectedSystem(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open('/systems/' + connectedSystems.find((system: any) => system.id === value.humphreeId)?.id, '_blank');
    } else navigate('/systems/' + value.humphreeId);
  }

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Systems connected to this model</h1>
      {isSystemIsLoading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading connected systems...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : !systemsGetError ? (
        <Table
          data={GetSystemListData(connectedSystems, oems, features, packages, boatModels)}
          columns={systemListColumns}
          onClick={(value: any, event?: any) => goToConnectedSystem(value, event)}
          searchValue={searchValue}
          type='connected systems'
          setColumnVisibility={{
            oemName: isHumphreeUser,
            software: size?.width > 1400,
            humphreeId: false,
            boatModel: false,
            hullNumber: size?.width > 1000,
          }}
        />
      ) : (
        <div className='flex justify-between items-center p-4 px-8 bg-error-100 border-x border-gray-10'>
          <span className='text-white prose-paragraphBase italic'>Could not load systems!</span>
        </div>
      )}
    </div>
  );
}

export default BoatDetailsSystems;
