import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import MeasurementFileDownload from '../components/Measurements/MeasurementFileDownload';
import { Measurement } from '../models/ApiModels';

type MeasurementsList = {
  id: string;
  systemSerial?: string;
  targetSerial?: string;
  targetName?: string;
  templateName?: string;
  iterationNumber?: string;
  iterationStartDate: Date;
  measurement: Measurement;
};

export function GetMeasurementsListData(measurements: Measurement[]) {
  const measurementsList: MeasurementsList[] = measurements.map((measurement: Measurement) => {
    return {
      id: measurement.id,
      systemSerial: measurement.masterSerial,
      targetSerial: measurement.targetSerial,
      targetName: measurement.targetName,
      templateName: measurement.templateName,
      iterationNumber: measurement.iterationNumber,
      iterationStartDate: measurement.iterationStartTimeUTC,
      measurement: measurement,
    };
  });

  return measurementsList;
}

const columnHelper = createColumnHelper<MeasurementsList>();

export const measurementsListColumns = [
  columnHelper.accessor('systemSerial', {
    header: 'System serial',
    cell: (info) => info.getValue(),
    size: 200,
  }),
  columnHelper.accessor('targetSerial', {
    header: 'Target serial',
    cell: (info) => info.getValue(),
    size: 200,
  }),
  columnHelper.accessor('targetName', {
    header: 'Target name',
    cell: (info) => info.getValue(),
    size: 200,
  }),
  columnHelper.accessor('templateName', {
    header: 'Template name',
    cell: (info) => info.getValue(),
    size: 200,
  }),
  columnHelper.accessor('iterationNumber', {
    header: 'Iteration number',
    cell: (info) => info.getValue(),
    size: 200,
  }),
  columnHelper.accessor('iterationStartDate', {
    header: 'Iteration start time(UTC)',
    cell: (info) => {
      return moment(info.getValue()).format('YYYY-MM-DD HH:mm:ss');
    },
    size: 200,
  }),
  columnHelper.accessor('measurement', {
    header: 'Download',
    cell: (info) => <MeasurementFileDownload measurement={info.getValue()} />,
    size: 200,
  }),
];
