import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input, PrimaryButton } from 'component-library';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SoftwareGroup } from '../../models/ApiModels';
import { GetSoftwareGroups } from '../../services/SoftwareUpdateService';
import useRequest from '../../utils/net/useRequest';
import Table from '../Table/Table';
import { GetSoftwareUpdateGroupsListData, softwareUpdateGroupsListColumns } from './SoftwareUpdateGroupsListSetup';

export function SoftwareUpdateGroups() {
  const [softwareGroups = [], isSoftwareGroupsLoading] = useRequest(() => GetSoftwareGroups(), []);
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  function goToSoftwareGroupDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open(
        '/software/group/' + softwareGroups.find((software: SoftwareGroup) => software.id === value.id).id,
        '_blank'
      );
    } else navigate(value.id);
  }

  function createSoftwareGroup() {
    setTimeout(() => {
      navigate('/software/create-group');
    }, 1);
  }

  useEffect(() => {
    const searchValueFromParams = searchParams.get('searchValue');
    setSearchValue(searchValueFromParams || '');
  }, [searchParams]);

  return (
    <>
      {!isSoftwareGroupsLoading ? (
        <>
          <div className='mt-10 border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
            <PrimaryButton onClick={() => createSoftwareGroup()} icon={faPlus} label='Create new group' />
          </div>
          <Table
            data={GetSoftwareUpdateGroupsListData(softwareGroups)}
            columns={softwareUpdateGroupsListColumns}
            onClick={(value: any, event?: any) => {
              goToSoftwareGroupDetails(value, event);
            }}
            searchValue={searchValue}
            type='software groups'
          />
        </>
      ) : (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading software groups...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
    </>
  );
}
