import { faArrowDown, faArrowRight, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InvertButton, Popup, PrimaryButton, StatusButton } from 'component-library';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import listImg from '../../../../assets/lcpIcons/autolisttest-01.png';
import autoTrimImg from '../../../../assets/lcpIcons/autotrimtest-01.png';
import sensitivityImg from '../../../../assets/lcpIcons/baseline_tune_black_24dp.png';
import coTurnImg from '../../../../assets/lcpIcons/coordinatedturn-new-01.png';
import extBusImg from '../../../../assets/lcpIcons/extbus.png';
import interceptorImg from '../../../../assets/lcpIcons/interceptorsetup-01.png';
import rudderImg from '../../../../assets/lcpIcons/ruddersignalnew-01.png';
import sensorImg from '../../../../assets/lcpIcons/sensorsetup-01.png';
import speedImg from '../../../../assets/lcpIcons/speedsource-01.png';

import _ from 'lodash';
import pitchImg from '../../../../assets/lcpIcons/waves-01.png';
import { AppContext } from '../../../../contexts/AppContext';
import { Preset } from '../../../../models/ApiModels';
import {
  DeletePreset,
  GetPresetDetails,
  GetPresetFile,
  UpdatePresetDetails,
} from '../../../../services/PresetsServices';
import { ActionType } from '../../../../store/actionTypes';
import PermissionGate, { ROLES } from '../../../../utils/PermissionGate';
import {
  autoTrimDataPreset,
  coTurnDataPreset,
  displayDataPreset,
  interceptorSetupDataPreset,
  listRollDataPreset,
  pitchDataPreset,
  rudderDataPreset,
  sensorDataPreset,
  speedSourceDataPreset,
} from '../../../DataExports/presetsData';
import { presetVisibilityFunctionData, presetVisibilitySystemData } from '../../../DataExports/presetVisibilityData';
import { ConfigurationFunctionSetupSettings } from '../../../Systems/SystemDetails/SystemDetailsConfiguration/ConfigurationFunctionSetupSettings';
import { ConfigurationOtherSettings } from '../../../Systems/SystemDetails/SystemDetailsConfiguration/ConfigurationOtherSettings';
import { ConfigurationSystemSetupSettings } from '../../../Systems/SystemDetails/SystemDetailsConfiguration/ConfigurationSystemSetupSettings';
import ConfigurationVisibilitySettings from '../../../Systems/SystemDetails/SystemDetailsConfiguration/ConfigurationVisibilitySettings';
import { OutletContextTypeBoatDetails } from '../../BoatModelDetailsLayout';

function BoatModelDetailsPresetDetails() {
  const componentMounted = useRef(true);
  const [
    boatModel,
    setBoatModel,
    oldBoatModel,
    GetBoatModelDetails,
    hasChanged,
    leavingPage,
    hasError,
    presets,
    isPresetsLoading,
    presetsGetError,
  ] = useOutletContext<OutletContextTypeBoatDetails[]>();
  const [preset, setPreset] = useState<Preset>();
  const { currentOemTenantId, isHumphreeUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [updatedPreset, setUpdatedPreset] = useState<Preset>();
  const { presetName } = useParams();
  const [edit, setEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const formatDate = (date: Date | string) => date.toString().slice(0, 10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    GetPresetInfo();
  }, [presetName]);

  function GetPresetInfo() {
    GetPresetDetails(presetName !== undefined ? presetName : '')
      .then((preset: Preset) => {
        setLoading(true);
        if (componentMounted.current) {
          setPreset({ ...preset });
          setUpdatedPreset(_.cloneDeep(preset));
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const presetChanged = useMemo(() => {
    if (preset) {
      return !_.isEqual(preset, updatedPreset);
    }
  }, [preset, updatedPreset]);

  function leaveEdit() {
    if (presetChanged) {
      setOpenPopup(true);
    } else {
      setEdit(!edit);
    }
  }

  function DiscardChanges() {
    if (preset) setUpdatedPreset({ ...preset });
    setOpenPopup(false);
    setEdit(false);
  }

  const visibilitySystem = presetVisibilitySystemData(
    preset !== undefined && (preset.lcuMetadata.menuVisibility as any),
    {
      sensorImg: sensorImg,
      signalImg: rudderImg,
      setupImg: interceptorImg,
      speedImg: speedImg,
      extBusImg: extBusImg,
    }
  );

  const visibilityFunction = presetVisibilityFunctionData(
    preset !== undefined && (preset.lcuMetadata.menuVisibility as any),
    {
      sensitivityImg: sensitivityImg,
      autoTrimImg: autoTrimImg,
      listImg: listImg,
      coTurnImg: coTurnImg,
      pitchImg: pitchImg,
    }
  );

  const deletePreset = useCallback(() => {
    setOpenDeletePopup(false);
    setLoading(true);
    if (preset)
      DeletePreset(preset.id, boatModel.oemTenantId)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Preset successfully deleted', status: 'success' },
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          setEdit(false);
          setLoading(false);
          navigate('/models/' + boatModel.id);
          // window.location.reload();
        });
  }, [preset, updatedPreset]);

  const updatePreset = useCallback(() => {
    setLoading(true);
    if (updatedPreset)
      UpdatePresetDetails(updatedPreset, updatedPreset.id, boatModel.oemTenantId)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Preset updated', status: 'success' },
          });
          setEdit(false);
          GetPresetInfo();
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          setLoading(false);
        });
  }, [updatedPreset]);

  function GetFile(unitType: string) {
    if (preset) {
      GetPresetFile(preset.id, unitType, currentOemTenantId)
        .then((res: any) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = `${preset.id}.hdst`;
          link.click();
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }

  return (
    <>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading preset...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        preset &&
        updatedPreset && (
          <>
            <div className='flex flex-col'>
              <PermissionGate roles={[ROLES.admin, ROLES.editor]} allowOemUsers rejected={undefined}>
                <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 lg:mt-11 lg:-mb-11 xl:mb-12 xl:-mt-12'>
                  {!edit ? (
                    <PrimaryButton
                      label={'Edit preset description'}
                      icon={faEdit}
                      onClick={() => setEdit(true)}
                      fullWidth
                    />
                  ) : (
                    <>
                      <div className='w-full bg-white'>
                        <InvertButton
                          label={'Leave edit'}
                          onClick={() => {
                            leaveEdit();
                          }}
                          fullWidth
                        />
                      </div>
                      <PrimaryButton
                        label={'Save preset description'}
                        disabled={!presetChanged}
                        onClick={() => updatePreset()}
                        fullWidth
                      />
                    </>
                  )}
                </div>
              </PermissionGate>
              <div className='flex flex-col gap-16 pt-12 lg:pt-2'>
                <div>
                  <h4 className='prose-heading4 mb-4'>Description</h4>
                  <div className='border-y border-gray-10 py-4'>
                    {!edit ? (
                      <p>{preset.description}</p>
                    ) : (
                      <Input
                        value={updatedPreset.description}
                        onChange={(value: string) => {
                          if (updatedPreset !== undefined) {
                            updatedPreset.description = value;
                            setUpdatedPreset({ ...updatedPreset });
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <ConfigurationSystemSetupSettings
                  isPreset
                  sensorData={sensorDataPreset(preset)}
                  rudderData={rudderDataPreset(preset)}
                  interceptorSetupData={interceptorSetupDataPreset(preset)}
                  speedSourceData={speedSourceDataPreset(preset)}
                />
                <ConfigurationFunctionSetupSettings
                  isPreset
                  autoTrimData={autoTrimDataPreset(preset)}
                  listRollData={listRollDataPreset(preset)}
                  coTurnData={coTurnDataPreset(preset)}
                  pitchData={pitchDataPreset(preset)}
                />
                <div className='flex gap-10 flex-wrap'>
                  <ConfigurationOtherSettings isPreset displayData={displayDataPreset(preset)} />
                  <ConfigurationVisibilitySettings data={preset.lcuMetadata.menuVisibility} />
                </div>
                <div>
                  <h4 className='prose-heading4 mb-4'>Other information</h4>
                  <div className='flex justify-between border-y border-gray-10 py-4'>
                    <p>Created date</p>
                    <p>{formatDate(preset.createdDate)}</p>
                  </div>
                  <div className='flex justify-between border-b border-gray-10 py-4'>
                    <p>Original system</p>
                    <div className='text-right'>
                      <p>{preset.systemId}</p>
                      <Link to={`/systems/${preset.systemId}`} className='cursor-pointer mt-4 prose-buttonStandard'>
                        Go to system details view <FontAwesomeIcon icon={faArrowRight} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-full lg:w-fit lg:flex-row gap-4 mt-4 ml-auto'>
                {edit ? (
                  <StatusButton
                    fullWidth
                    icon={faTrash}
                    label={preset.status === 0 ? 'Delete preset' : 'Retire preset'} // if status is created, delete
                    status={'error'}
                    onClick={() => setOpenDeletePopup(true)}
                  />
                ) : (
                  <>
                    {isHumphreeUser && (
                      <>
                        <PrimaryButton
                          fullWidth
                          label='Download LCP preset'
                          onClick={() => GetFile('LCP')}
                          icon={faArrowDown}
                        />
                        <PrimaryButton
                          fullWidth
                          label='Download LCU preset'
                          onClick={() => GetFile('LCU')}
                          icon={faArrowDown}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )
      )}
      {openDeletePopup && (
        <Popup
          close={() => setOpenDeletePopup(false)}
          confirm={() => deletePreset()}
          heading='Retire or something?'
          paragraph='This will permanently delete or retire the thing'
          status='error'
          cancelText='Keep preset'
          confirmText='Yes, do it'
        />
      )}
      {openPopup && (
        <Popup
          close={() => setOpenPopup(false)}
          confirm={DiscardChanges}
          heading='Discard preset changes?'
          paragraph='You will lose all the changes made to this preset.'
          status='error'
          cancelText='Keep editing'
          confirmText='Discard changes'
        />
      )}
    </>
  );
}

export default BoatModelDetailsPresetDetails;
