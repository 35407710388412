import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { Input, InvertButton, PrimaryButton, Tooltip } from 'component-library';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../contexts/AppContext';
import { Oem, OemIndirect } from '../../models/ApiModels';
import { GetOem, GetOems, GetOemsIndirect, UpdateOemAsync } from '../../services/OemService';
import { ActionType } from '../../store/actionTypes';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import PermissionsGate, { ROLES } from '../../utils/PermissionGate';

export function SettingsOrder() {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentOem, currentOemTenantId, isHumphreeUser, setCurrentOem } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const selectedOem: Oem = oems.find((oem: Oem) => oem.id === currentOemTenantId) || currentOem;
  const [updateOem, setUpdateOem] = useState(_.cloneDeep(selectedOem));

  const hasChanged = useMemo(() => {
    return !_.isEqual(selectedOem, updateOem);
  }, [updateOem, selectedOem]);

  function UpdateOem() {
    setLoading(true);
    if (updateOem) {
      UpdateOemAsync(updateOem)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully updated OEM!', status: 'success' },
          });
          if (isHumphreeUser) {
            GetOems()
              .then((oems: Oem[]) => {
                dispatch({
                  type: ActionType.ADD_OEMS,
                  payload: oems,
                });
              })
              .catch((e: any) => {
                console.log(e);
              });
          } else {
            GetOem(currentOemTenantId)
              .then((res: Oem) => {
                if (res.parentOEMTenantId === null) {
                  GetOemsIndirect(currentOemTenantId)
                    .then((oems: OemIndirect[]) => {
                      dispatch({
                        type: ActionType.ADD_OEMS,
                        payload: oems,
                      });
                    })
                    .catch((e: any) => {
                      console.log(e);
                    });
                }
                setCurrentOem(res);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .finally(() => {
          setEditMode(false);
          setLoading(false);
        });
    }
  }
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className='flex flex-col pt-8'>
          <PermissionsGate roles={[ROLES.admin, ROLES.editor]} allowOemUsers rejected={undefined}>
            <div className='flex gap-2 ml-auto w-max sticky -mt-20 mb-20 top-0 right-0 z-10'>
              {!editMode ? (
                <PrimaryButton label={'Edit order settings'} icon={faEdit} onClick={() => setEditMode(!editMode)} />
              ) : (
                <>
                  <InvertButton
                    label={'Leave edit'}
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  />
                  <PrimaryButton
                    label={'Save settings'}
                    disabled={!hasChanged}
                    onClick={() => {
                      UpdateOem();
                    }}
                  />
                </>
              )}
            </div>
          </PermissionsGate>
          <div className='flex flex-col border-b border-gray-10 pb-5'>
            <span className='prose-heading4 pb-2'>Order settings</span>
            <span className='prose-paragraphSmall text-gray-60'>
              Edit settings related to ordering from the portal.
            </span>
            <div className='flex flex-row justify-between w-full mt-8'>
              <div className='flex flex-row gap-2 items-center justify-start'>
                <span className='prose-paragraphBase text-gray-100'>Default order confirmation email</span>
                <Tooltip description='Default order confirmation email' />
              </div>
              {!editMode ? (
                <span className='prose-paragraphBase text-gray-100'>
                  {selectedOem?.settings.orderConfirmationEmail || currentOem?.settings.orderConfirmationEmail}
                </span>
              ) : (
                <div className='w-60'>
                  <Input
                    placeholder='Enter email...'
                    value={updateOem?.settings?.orderConfirmationEmail || ''}
                    onChange={(value: string) => {
                      if (updateOem && updateOem.settings) {
                        updateOem.settings.orderConfirmationEmail = value;
                        setUpdateOem({ ...updateOem });
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
