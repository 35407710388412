import { MenuVisibility } from '../../models/ApiModels';

const presetVisibilitySystemData = (presetVisibility?: MenuVisibility, imgData?: any) => [
  { key: 'Sensor', value: presetVisibility?.sensor, img: imgData.sensorImg },
  { key: 'Rudder signal', value: presetVisibility?.rudder, img: imgData.signalImg },
  {
    key: 'Interceptor setup',
    value: presetVisibility?.interceptorOffset,
    img: imgData.setupImg,
  },
  { key: 'Speed source', value: presetVisibility?.speedSource, img: imgData.speedImg },
  { key: 'Ext bus', value: presetVisibility?.extbus, img: imgData.extBusImg },
];

const presetVisibilityFunctionData = (presetVisibility?: MenuVisibility, imgData?: any) => [
  { key: 'Sensitivity', value: presetVisibility?.sensitivity, img: imgData.sensitivityImg },
  { key: 'Auto trim', value: presetVisibility?.autoTrim, img: imgData.autoTrimImg },
  {
    key: 'List & Roll control',
    value: presetVisibility?.listAndRollControl,
    img: imgData.listImg,
  },
  {
    key: 'Coordinated turn',
    value: presetVisibility?.coordinatedTurn,
    img: imgData.coTurnImg,
  },
  { key: 'Pitch control', value: presetVisibility?.pitchControl, img: imgData.pitchImg },
];

export { presetVisibilitySystemData, presetVisibilityFunctionData };
