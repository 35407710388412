import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { BoatModel, Feature, Oem, Package, System } from '../models/ApiModels';
import { GetStatusColor, GetStatusText } from './Custom';

type SystemsListProps = {
  oemName?: string;
  bcOrder: string;
  humphreeId?: string;
  software: string[];
  status: string;
  boatModel?: string;
  hullNumber?: string;
  serialNumber?: number;
  oemId?: string;
};

export function GetSystemListData(
  systems: System[],
  oems: Oem[],
  features: Feature[],
  packages: Package[],
  boatModels?: BoatModel[],
  toggleInternal?: boolean
) {
  const { isHumphreeUser, currentOem } = useContext(AppContext);
  const systemList: SystemsListProps[] = systems.map((system: System) => {
    const boatMake = boatModels?.find((boatModel: BoatModel) => boatModel.id === system.boatModelId)?.make || '';
    const boatName = boatModels?.find((boatModel: BoatModel) => boatModel.id === system.boatModelId)?.name || '';

    return {
      oemId: system.oemId,
      oemName: oems.find((oem: Oem) => oem.id === system.oemId)?.name || (currentOem && currentOem.name),
      bcOrder: '1X23218',
      humphreeId: system.id,
      software:
        [
          packages.find((packageItem: Package) => packageItem.id === system.packageId)?.displayName?.toString() || '',
          (features.find((feature: Feature) => feature.id === system.addonFeatureIds.toString()) && 'UWL') || '',
        ] || [],
      status: system.status,
      boatModel: boatMake + ' ' + boatName,
      hullNumber: system.hullNumber,
      serialNumber: system.controlUnitSerialNumber,
    };
  });

  //Hide internal (OEM Humphree Internal in PROD)
  const systemListNoInternal = systemList.filter(
    (system: any) => system.oemId !== 'd351d5d3-e474-47eb-b079-70c32f15683f'
  );

  //Remove retired and deleted for Oems
  const systemListHideForOem = systemList.filter(
    (system: any) => system.status !== 'Retired' && system.status !== 'Deleted'
  );
  if (!isHumphreeUser) return systemListHideForOem;
  else if (toggleInternal) return systemListNoInternal;
  else return systemList;
}

const columnHelper = createColumnHelper<SystemsListProps>();

export const systemListColumns = [
  columnHelper.accessor('humphreeId', {
    header: 'Humphree-ID',
    cell: (info) => info.getValue(),
    size: 65,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('serialNumber', {
    header: 'S/N',
    cell: (info) => {
      return <div className='truncate font-code prose-dataBaseDataLabel'>{info.getValue()}</div>;
    },
    size: 60,
  }),
  columnHelper.accessor('oemName', {
    header: 'Oem',
    cell: (info) => {
      return <div className='truncate'>{info.getValue()}</div>;
    },
    size: 120,
  }),
  columnHelper.accessor('boatModel', {
    header: 'Boat model',
    cell: (info) => {
      return (
        <div className={`${info.getValue() === ' ' && 'italic text-gray-40'}`}>
          {info.getValue() === ' ' ? 'Not assigned' : info.getValue()}
        </div>
      );
    },
    size: 100,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const valueA = rowA.original[columnId];
      const valueB = rowB.original[columnId];

      // Handle undefined values
      if (valueA === undefined && valueB === undefined) return 0;
      if (valueA === undefined) return 1;
      if (valueB === undefined) return -1;

      // Your custom sorting logic for defined values
      return valueA.localeCompare(valueB);
    },
  }),
  columnHelper.accessor('hullNumber', {
    header: 'Hull number',
    cell: (info) => {
      return <div className={`${!info.getValue() && 'italic text-gray-40'}`}>{info.getValue() || 'Not assigned'}</div>;
    },
    size: 105,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const valueA = rowA.original[columnId];
      const valueB = rowB.original[columnId];

      // Handle undefined values
      if (valueA === undefined && valueB === undefined) return 0;
      if (valueA === undefined) return 1;
      if (valueB === undefined) return -1;

      // Your custom sorting logic for defined values
      return valueA.localeCompare(valueB);
    },
  }),
  columnHelper.accessor('software', {
    header: 'Humphree license',
    size: 150,
    cell: (info) => {
      return (
        <div className='flex 2xl:w-full xl:w-96 2xl:flex-nowrap flex-wrap flex-row gap-2 p-2 pl-0 truncate'>
          {info.getValue().map((i: string, index) => {
            return <div key={index}>{i !== '' && <StatusPill color='Dark Gray' text={i} />}</div>;
          })}
        </div>
      );
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (info) => (
      <StatusPill color={GetStatusColor(info.getValue()) as any} text={GetStatusText(info.getValue())} fixedSize />
    ),
    enableGlobalFilter: false,
    size: 140,
  }),
];
