import { faArrowDown, faBandAid, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, StatusButton } from 'component-library';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BoatModelPatches, Patch } from '../../../models/ApiModels';
import { DeletePatchFile, GetPatchFile, GetPatchFileDownload } from '../../../services/PresetsServices';
import PermissionsGate, { ROLES } from '../../../utils/PermissionGate';
import UploadFile from '../../../utils/UploadFile';
import { OutletContextTypeBoatDetails } from '../BoatModelDetailsLayout';

export default function BoatModelDetailsPatches() {
  const [
    boatModel,
    setBoatModel,
    oldBoatModel,
    GetBoatModelDetails,
    loading,
    hasChanged,
    leavingPage,
    hasError,
    setLoading,
    setOpenPopup,
    openPopup,
    presets,
  ] = useOutletContext<OutletContextTypeBoatDetails[]>();
  const initPatch: Patch = {
    id: '',
    createdDate: new Date(),
  };
  const [LCUPatch, setLCUPatch] = useState<Patch>(initPatch);
  const [LCPPatch, setLCPPatch] = useState<Patch>(initPatch);
  const [uploadedLCU, setUploadedLCU] = useState(false);
  const [uploadedLCP, setUploadedLCP] = useState(false);
  const [LCUSrc, setLCUSrc] = useState(null);
  const [LCPSrc, setLCPSrc] = useState(null);

  const boatModelLCUPatchId = boatModel?.patches?.find((patch: BoatModelPatches) => patch.unitType === 'LCU')?.id;
  const boatModelLCPPatchId = boatModel?.patches?.find((patch: BoatModelPatches) => patch.unitType === 'LCP')?.id;

  useEffect(() => {
    if (boatModelLCUPatchId)
      GetPatchFile(boatModelLCUPatchId, boatModel.oemTenantId)
        .then((response) => {
          setLCUSrc(response);
          setUploadedLCU(true);
        })
        .catch((error) => {
          setLCUSrc(null);
          setUploadedLCU(false);
        });
    if (boatModelLCPPatchId)
      GetPatchFile(boatModelLCPPatchId, boatModel.oemTenantId)
        .then((response) => {
          setLCPSrc(response);
          setUploadedLCP(true);
        })
        .catch((error) => {
          setLCPSrc(null);
          setUploadedLCP(false);
        });
  }, []);

  function GetFile(id: string) {
    GetPatchFileDownload(id, boatModel.oemId)
      .then((res: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = `${id}.hdst`;
        link.click();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const deleteLCUPatch = useCallback(() => {
    setLoading(true);
    DeletePatchFile(boatModel.id, 'LCU', boatModel.oemTenantId)
      .then(() => {
        setLCUSrc(null);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
  }, []);

  const deleteLCPPatch = useCallback(() => {
    setLoading(true);
    DeletePatchFile(boatModel.id, 'LCP', boatModel.oemTenantId)
      .then((response) => {
        setLCPSrc(null);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
  }, []);

  return (
    <div>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading patches...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <PermissionsGate roles={[ROLES.admin, ROLES.editor, ROLES.softwareManager]} rejected={'Not authorized'}>
          <div className='flex flex-col gap-16'>
            <div className='mt-10 gap-2'>
              <h4 className='prose-heading4'>
                <FontAwesomeIcon icon={faBandAid} /> Patch
              </h4>
              <p className='py-2 border-b border-gray-10'>This view is only visible to humphree users</p>
            </div>
            <div className='flex flex-col gap-2'>
              <h4 className='prose-heading4'>LCU Patch</h4>
              <p className='py-2 border-b border-gray-10'>Upload a patch for the LCU here</p>
              {!uploadedLCU ? (
                <UploadFile
                  data={LCUPatch}
                  setData={(file: any) => setLCUPatch({ ...file })}
                  appendData={[{ key: 'unitType', value: 'LCU' }]}
                  uploadType={'LCU patch'}
                  reload
                  validData={LCUPatch.createdDate !== undefined && LCUPatch.id !== undefined}
                  uploadButtonLabel={'Upload LCU patch'}
                  requestUrl={`v1/boats/models/${boatModel.id}/patches/LCU`}
                />
              ) : (
                <>
                  <code lang='xml' className='h-[300px] bg-gray-10 p-2 max-w-4xl overflow-y-auto whitespace-pre-wrap'>
                    {LCUSrc}
                  </code>

                  <div className='flex flex-wrap ml-auto gap-5'>
                    <PrimaryButton
                      icon={faArrowDown}
                      label='Download LCU data patch'
                      onClick={() => GetFile(boatModelLCUPatchId)}
                    />
                    <StatusButton
                      icon={faTrash}
                      label='Delete LCU data patch'
                      status={'error'}
                      onClick={() => deleteLCUPatch()}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='flex flex-col gap-2'>
              <h4 className='prose-heading4'>LCP Patch</h4>
              <p className='py-2 border-b border-gray-10'>Upload a patch for the LCP here</p>
              {!uploadedLCP ? (
                <UploadFile
                  data={LCPPatch}
                  setData={(file: any) => setLCPPatch({ ...file })}
                  appendData={[{ key: 'unitType', value: 'LCP' }]}
                  validData={LCPPatch.createdDate !== undefined && LCPPatch.id !== undefined}
                  uploadType={'LCP patch'}
                  reload
                  uploadButtonLabel={'Upload LCP patch'}
                  requestUrl={`v1/boats/models/${boatModel.id}/patches/LCP`}
                />
              ) : (
                <>
                  <code className='h-[300px] bg-gray-10 p-2 max-w-4xl overflow-y-auto whitespace-pre-wrap'>
                    {LCPSrc}
                  </code>

                  <div className='flex flex-wrap ml-auto gap-5'>
                    <PrimaryButton
                      icon={faArrowDown}
                      label='Download LCP data patch'
                      onClick={() => GetFile(boatModelLCPPatchId)}
                    />
                    <StatusButton
                      icon={faTrash}
                      label='Delete LCP data patch'
                      status={'error'}
                      onClick={() => deleteLCPPatch()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </PermissionsGate>
      )}
    </div>
  );
}
