import { createColumnHelper } from '@tanstack/react-table';
import { SoftwareGroup } from '../../models/ApiModels';

type SoftwareUpdateLíst = {
  id?: string;
  systemType: string;
  groupName: string;
};
export function GetSoftwareUpdateGroupsListData(groups: SoftwareGroup[]) {
  const softwareUpdateGroupsList: SoftwareUpdateLíst[] = groups.map((groups: SoftwareGroup) => {
    return {
      id: groups.id,
      systemType: groups.assignedSystemType,
      groupName: groups.groupName,
    };
  });

  return softwareUpdateGroupsList;
}

const columnHelper = createColumnHelper<SoftwareUpdateLíst>();

export const softwareUpdateGroupsListColumns = [
  columnHelper.accessor('systemType', {
    header: 'System type',
    cell: (info) => info.getValue(),
    size: 0,
  }),

  columnHelper.accessor('groupName', {
    header: 'Group name',
    cell: (info) => info.getValue(),
  }),
];
