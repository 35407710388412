import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { InvertButton, Popup, PrimaryButton, StatusButton } from 'component-library';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BoatModel, Oem, System } from '../../../models/ApiModels';
import PageNotFound from '../../../routes/PageNotFound';
import { DeleteBoatModel, UpdateBoatModels } from '../../../services/BoatModelsService';
import { ActionType } from '../../../store/actionTypes';
import PermissionGate, { ROLES } from '../../../utils/PermissionGate';
import { OutletContextTypeBoatDetails } from '../BoatModelDetailsLayout';
import BoatDetailsImage from './BoatDetailsImage';
import BoatDetailsSystems from './BoatDetailsSystems';
import BoatModelDetailsInformation from './BoatModelDetailsInformation';
import BoatModelDetailsSpecification from './BoatModelDetailsSpecification';

function BoatModelDetails() {
  const [
    boatModel,
    setBoatModel,
    oldBoatModel,
    GetBoatModelDetails,
    loading,
    hasChanged,
    leavingPage,
    hasError,
    setLoading,
    setOpenPopup,
    openPopup,
  ] = useOutletContext<OutletContextTypeBoatDetails[]>();

  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isValidNumbers, setIsValidNumbers] = useState(true);
  const systems = useSelector((state: any) => state.systems.systems);
  const oems = useSelector((state: any) => state.oems.data);
  const connectedSystems: System[] =
    boatModel && systems.filter((system: System) => system.boatModelId === boatModel.id);

  function DiscardChanges() {
    if (leavingPage) {
      setTimeout(() => {
        navigate('/models');
      }, 1);
    }
    if (oldBoatModel) setBoatModel({ ...oldBoatModel });
    setOpenPopup(false);
    setEdit(false);
  }

  function leaveEdit() {
    if (hasChanged) {
      setOpenPopup(true);
    } else {
      setEdit(!edit);
    }
  }

  const updateBoatModelDetails = useCallback((updatedBoatModel: BoatModel) => {
    setLoading(true);
    UpdateBoatModels(updatedBoatModel)
      .then(() => {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'Success', paragraph: 'Boat model has been updated', status: 'success' },
        });
        setEdit(false);
        GetBoatModelDetails();
      })
      .catch(() => {
        console.log('Something went wrong while updating the boat model');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const DeleteBoat = useCallback(() => {
    setOpenDeletePopup(false);
    setLoading(true);
    if (boatModel !== undefined) {
      DeleteBoatModel(boatModel.id)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Boat model successfully deleted', status: 'success' },
          });
          navigate('/models');
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [boatModel]);

  const currentOem = useMemo(() => {
    if (boatModel !== undefined) {
      return oems.find((oem: Oem) => {
        return oem.id === boatModel.oemTenantId;
      });
    }
  }, [boatModel]);

  return (
    <>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading boat model...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : !hasError && boatModel ? (
        <>
          <div className='flex flex-col gap-8'>
            <PermissionGate roles={[ROLES.admin, ROLES.editor]} allowOemUsers rejected={undefined}>
              <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 lg:mb-12 lg:-mt-12'>
                {!edit ? (
                  <PrimaryButton label={'Edit boat details'} icon={faEdit} onClick={() => setEdit(true)} fullWidth />
                ) : (
                  <>
                    <div className='w-full bg-white'>
                      <InvertButton
                        label={'Leave edit'}
                        onClick={() => {
                          leaveEdit();
                        }}
                        fullWidth
                      />
                    </div>
                    <PrimaryButton
                      label={'Save boat model'}
                      disabled={!hasChanged}
                      onClick={() => updateBoatModelDetails(boatModel)}
                      fullWidth
                    />
                  </>
                )}
              </div>
            </PermissionGate>
            <div className='flex flex-wrap gap-4'>
              <div className='prose-heading3 lg:prose-heading1 italic bg-gradient-to-b from-gray-100 to-gray-40 bg-clip-text text-black text-opacity-0'>
                {boatModel.make}
              </div>
              <div className='prose-heading3 lg:prose-heading1 italic bg-gradient-to-b from-primary-400 to-primary-100 bg-clip-text text-black text-opacity-0'>
                {boatModel.name}
              </div>
            </div>
            <div className='flex flex-col gap-16 lg:flex-row'>
              <div className='w-full lg:w-1/2'>
                <BoatDetailsImage edit={edit} />
              </div>
              <div className='lg:w-1/2'>
                <BoatModelDetailsSpecification
                  boatModel={boatModel}
                  editMode={edit}
                  setUpdateBoatModel={(updateBoatModel: BoatModel) => setBoatModel({ ...updateBoatModel })}
                  updateBoatModel={boatModel}
                  setIsValid={(value: boolean) => setIsValidNumbers(value)}
                />
              </div>
            </div>
            <BoatModelDetailsInformation
              currentOem={currentOem?.name ?? ''}
              boatModel={boatModel}
              editMode={edit}
              setUpdateBoatModel={(updateBoatModel: BoatModel) => setBoatModel({ ...updateBoatModel })}
              updateBoatModel={boatModel}
            />
            <BoatDetailsSystems boatModel={boatModel} />
            {edit && (
              <div className='flex justify-between items-center'>
                <span className='prose-buttonStandard'>
                  Boat model cannot be deleted if systems are connected to it
                </span>
                <StatusButton
                  onClick={() => setOpenDeletePopup(true)}
                  icon={faTrash}
                  label='Delete boat model'
                  status={'error'}
                  disabled={connectedSystems.length !== 0}
                />
              </div>
            )}
          </div>
          {loading && (
            <div className='w-full flex justify-center items-center h-full'>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          )}
        </>
      ) : (
        <PageNotFound />
      )}
      {openPopup && (
        <Popup
          close={() => setOpenPopup(false)}
          confirm={DiscardChanges}
          heading='Discard boat model changes?'
          paragraph='You will lose all the changes made to this boat model.'
          status='error'
          cancelText='Keep editing'
          confirmText='Discard changes'
        />
      )}
      {openDeletePopup && (
        <Popup
          close={() => setOpenDeletePopup(false)}
          confirm={() => DeleteBoat()}
          heading='Delete current boat model?'
          paragraph='This will permanently delete the boat model'
          status='error'
          cancelText='Keep boat model'
          confirmText='Delete boat model'
        />
      )}
    </>
  );
}

export default BoatModelDetails;
