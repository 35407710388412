import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
interface Props {
  roles: string[];
  children?: any;
  rejected: any;
  allowOemUsers?: boolean;
}

export const ROLES = {
  editor: 'editor',
  admin: 'admin',
  softwareManager: 'softwareManager',
  presetCertified: 'presetCertified',
};
export default function PermissionsGate(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  const { accounts } = useMsal();
  const account = accounts[0];
  const roles: string = account?.idTokenClaims?.extension_userRoles as string;

  if (props.roles.some((role: string) => roles?.includes(role))) {
    if (props.allowOemUsers) return <>{props.children}</>;
    else if (isHumphreeUser) return <>{props.children}</>;
  }
  return <>{props.rejected}</>;
}
