/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Oem } from '../models/ApiModels';

type AppContextType = {
  currentOemTenantId: string;
  setCurrentOemTenantId: (oem: string) => void;
  loadingOem: boolean;
  setLoadingOem: (value: boolean) => void;
  isHumphreeUser: boolean;
  isBusinessCustomer: boolean;
  setIsHumphreeUser: (value: boolean) => void;
  currentOem?: Oem;
  setCurrentOem: (oem: Oem | undefined) => void;
};

export const AppContext = createContext<AppContextType>({
  currentOemTenantId: '',
  setCurrentOemTenantId: (oem: string) => {},
  loadingOem: false,
  setLoadingOem: (value: boolean) => {},
  isHumphreeUser: false,
  isBusinessCustomer: false,
  setIsHumphreeUser: (value: boolean) => {},
  currentOem: undefined,
  setCurrentOem: (oem: Oem | undefined) => {},
});

const AppProvider = ({ children }: any) => {
  const [currentOemTenantId, setCurrentOemTenantId] = useState('');
  const [loadingOem, setLoadingOem] = useState(false);
  const [isHumphreeUser, setIsHumphreeUser] = useState(false);
  const [currentOem, setCurrentOem] = useState<Oem | undefined>();

  //case 1: logged in as humphree user and representing humphree home
  //case 2: logged in as a indirect user or representing a indirect user
  //case 3: logged in as a business user or representing a business user
  const oems = useSelector((state: any) => state.oems.data);
  const selectedOem = oems.find((oem: Oem) => oem.id === currentOemTenantId);
  const isBusinessCustomer =
    selectedOem?.parentOEMTenantId === null || (currentOem?.parentOEMTenantId === null && !selectedOem);

  return (
    <AppContext.Provider
      value={{
        currentOemTenantId,
        setCurrentOemTenantId,
        loadingOem,
        setLoadingOem,
        isHumphreeUser,
        isBusinessCustomer,
        setIsHumphreeUser,
        currentOem,
        setCurrentOem,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
